import { createStore as reduxCreateStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

const reducer = (state, action) => {
  if (action.type === "LOADING_START") {
    return { ...state, isLoading: true, tokenCollectionError: {} };
  }

  if (action.type === "UPDATE_MODAL_DATA") {
    console.log("UPDATE_MODAL_DATA");
    console.log(action.data);
    return { ...state, modalData: action.data };
  }
  if (action.type === "UPDATE_ACTIVE_TOKEN") {
    console.log("UPDATE_ACTIVE_TOKEN");
    console.log(action.data);
    return { ...state, activeToken: action.data };
  }
  if (action.type === "RESET_ACTIVE_TOKEN") {
    return { ...state, activeToken: {} };
  }
  if (action.type === "UPDATE_TOKEN_COLLECTION") {
    return { ...state, isLoading: false, tokenCollection: action.data };
  }
  if (action.type === "UPDATE_TOKEN_COLLECTION_ERROR") {
    return { ...state, isLoading: false, tokenCollectionError: action.data };
  }
  if (action.type === "UPDATE_TOKEN_DATA") {
    console.log("** starting UPDATE_TOKEN_DATA");
    console.log("state.tokenData");
    console.log(state.tokenData);
    console.log("action.data");
    console.log(action.data);
    return {
      ...state,
      isLoading: false,
      tokenData: state.tokenData?.[action.tokenAnonId]
        ? state.tokenData.map((tokenItem) => {
            console.log("UPDATE_TOKEN_DATA:: tokenItem");
            console.log(tokenItem);
            console.log("UPDATE_TOKEN_DATA:: action.data.tokenId");
            console.log(action.data.tokenId);
            if (tokenItem?.tokenId === action.data?.tokenId) {
              return action.data;
            } else {
              return tokenItem;
            }
          })
        : [...state.tokenData, { [action?.tokenAnonId]: action.data }],
    };
  }
  if (action.type === "UPDATE_TOKEN_DATA_DEMO") {
    return {
      ...state,
      isLoading: false,
      tokenData: state.productData,
    };
  }
  if (action.type === "UPDATE_TOKEN_DATA_ERROR") {
    return { ...state, isLoading: false, tokenDataError: action.data };
  }

  return state;
};

const initialState = {
  isLoading: false,
  tokenCollection: [],
  // tokenCollection: {
  // tokens: [
  //   {
  //     id: 1,
  //     title: "Women’s ATPV 9.7 Long-sleeve Half-button shirt",
  //     properties: [
  //       { name: "power", value: "HRC 2: 9.7 Cal / cm2" },
  //       { name: "fire", value: "Arc Shield FR 185gsm" },
  //       { name: "compliance", value: "AS/NZS 4836: 2023 Compliant" },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     title: "Men’s ATPV 9.7 Long-sleeve Half-button shirt",
  //     properties: [
  //       { name: "power", value: "HRC 2: 9.7 Cal / cm2" },
  //       { name: "fire", value: "Arc Shield FR 185gsm" },
  //       { name: "compliance", value: "AS/NZS 4836: 2023 Compliant" },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     title: "Men’s ATPV 15 Cargo Trousers",
  //     properties: [
  //       { name: "power", value: "HRC 2: 15 Cal / cm2" },
  //       { name: "fire", value: "Arc Shield FR 215gsm" },
  //       { name: "reflect", value: "50mm Silver Reflective Taping" },
  //     ],
  //   },
  //   {
  //     id: 4,
  //     title: "Women’s ATPV 15 Cargo Trousers",
  //     properties: [
  //       { name: "power", value: "HRC 2: 15 Cal / cm2" },
  //       { name: "fire", value: "Arc Shield FR 215gsm" },
  //       { name: "reflect", value: "50mm Silver Reflective Taping" },
  //     ],
  //   },
  // ],
  // },
  tokenData: [],
  tokenCollectionError: {},
  tokenDataError: {},
  modalData: {},
  activeToken: {},

  activePageId: "1",
  productData: {
    properties: [
      { name: "power", value: "HRC 2: 9.7 Cal / cm2" },
      { name: "fire", value: "Arc Shield FR 185 gsm" },
      { name: "compliance", value: "AS/NZS 4836: 2023 Compliant" },
    ],
  },
};

const createStore = () =>
  reduxCreateStore(reducer, initialState, applyMiddleware(thunk));

export default createStore;
